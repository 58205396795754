//
// xxx
// Stand: xxx
// © hofff.com | Nicky Hoff
// Styles für Formulare
// --------------------------------------------------


// Formular
// --------------------------------------------------

.formbody {

  .widget:not(.widget-hr) {
    margin-bottom: $paragraph-margin-bottom;

    label {
      @extend .form-label;

      cursor: pointer;
    }

    input.text,
    input.captcha,
    textarea {
      @extend .form-control;
    }

    select {
      @extend .form-select;
    }

    legend {
      @include font-size($font-size-base);

      p {
        padding-bottom: 0;
      }
    }

    input,
    textarea,
    select {

      &.error {
        @extend .is-invalid;
      }
    }

    &.widget-radio,
    &.widget-checkbox {

      legend {
        @include font-size($form-label-font-size);

        float: none;
        color: $form-label-color;
      }

      input {
        @extend .form-check-input;

        cursor: pointer;
      }

      label {
        @extend .form-check-label;

        margin-bottom: 0;
        cursor: pointer;
      }

      &.error {

        label {
          color: $danger;

          &::before {
            border-color: $danger;
          }
        }
      }

      > fieldset {

        > span {
          @extend .form-check;
        }
      }

      &.col_2 {

        @include media-breakpoint-up(md) {

          > fieldset {
            display: flex;
            flex-wrap: wrap;

            > legend,
            > span {
              flex: 0 0 auto;
            }

            > legend {
              width: 100%;
            }

            > span {
              width: 50%;
              padding-right: $grid-gutter-width;
            }
          }
        }
      }
    }

    &.widget-switch {

      > fieldset {

        > span {
          @extend .form-switch;
        }
      }
    }

    &.widget-explanation,
    &.widget-headline {

      :last-child {
        margin-bottom: 0;
      }
    }

    &.widget-calendar {

      > div {
        position: relative;
        padding-right: $input-height;

        input {
          border-right: none;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        .input-button {
          position: absolute;
          top: 0;
          right: 0;
          width: $input-height;
          height: $input-height;
          overflow: hidden;
          background-color: $primary;
          border: $border-width solid $border-color;
          border-left: none;
          border-radius: 0 $input-border-radius $input-border-radius 0;
          cursor: pointer;

          &::after {
            @include icon-far($fa-var-calendar-days);
            position: absolute;
            top: 0;
            right: 0;
            left: 0;

            color: $white;
            font-size: $font-size-lg;
            line-height: 1.8em;
            text-align: center;
          }

          svg {
            position: relative;
            z-index: -1;
          }
        }
      }
    }

    &.widget-captcha {

      label {
        display: block;
      }

      input {
        display: inline-block;
        width: 20%;
      }

      .captcha_text {
        @extend .text-muted;

        padding-left: map-get($spacers, 050);
        font-size: $font-size-sm;
      }
    }

    &.widget-submit {

      button {
        @extend .btn;
        @extend .btn-primary;
      }
    }
  }
}

// Form Message
// --------------------------------------------------

.form-message {
  @extend .alert;

  :last-child {
    margin-bottom: 0;
  }

  .card & {
    margin: ($grid-gutter-width * .5) ($grid-gutter-width * .5) 0;
  }

  a {
    @extend .alert-link;
  }

  &.form-message-error {
    @extend .alert-danger;
  }

  &.form-message-success {
    @extend .alert-success;
  }
}


// XXX
// --------------------------------------------------
