//
// xxx
// Stand: xxx
// © hofff.com | Nicky Hoff
// Allgemeine Styles
// --------------------------------------------------


// Schrift
// --------------------------------------------------

b,
strong {
  font-weight: $font-weight-medium;
}

.font-narrow {
  font-family: $font-family-sans-narrow;
}


// Überschriften & Absätze ALLGEMEIN
// --------------------------------------------------

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-top: map-get($spacers, 200);
  hyphens: auto;

  @include media-breakpoint-up(lg) {
    hyphens: manual;
  }
}

h1 {
  position: relative;
  margin-top: map-get($spacers, 200) !important;
  margin-bottom: map-get($spacers, 200) !important;
  padding-top: map-get($spacers, 050);
  text-transform: uppercase;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 70px;
    height: 3px;
    background-color: $primary;
    content: " ";
  }

  &[class*="col-"]::before {
    left: ($grid-gutter-width * .5);
  }
}

h2 {
  color: $gray-700;
}

h3 {
}

h4 {

  #subfooter & {
    margin-top: 0;
    text-transform: uppercase;
  }
}

h5 {
}

h6 {
}

p {

  &.ce_headline_virtual {

    footer & {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}


// Listen
// --------------------------------------------------

ol,
ul,
dl {
  margin-bottom: ($paragraph-margin-bottom * .5);
  padding-left: 1.3em;
}

ol,
ul {

  li {
    padding-bottom: ($paragraph-margin-bottom * .5);
  }
}

ul {

  .text-center & {
    list-style: inside;
  }

  &.list-icon {
    padding-left: 1.3em;
    list-style: none;

    li {
      text-indent: -1.3em;

      &::before {
        @include icon-fas();

        float: left;
        width: 1.3em;
        color: $primary;
      }
    }

    &.list-check {

      li {

        &::before {
          content: my-icon1($fa-var-check);
        }
      }
    }

    &.list-check-circle {

      li {

        &::before {
          content: my-icon1($fa-var-check-circle);
        }
      }
    }

    &.list-special {
      font-weight: $font-weight-bold;
      font-size: $font-size-lg;

      li {

        &::before {
          color: $success;
        }
      }
    }

    &.list-minus {

      li {

        &::before {
          content: my-icon1($fa-var-minus);
        }
      }
    }

    &.list-plus-circle {

      li {

        &::before {
          content: my-icon1($fa-var-plus-circle);
        }
      }
    }
  }
}

.list-check-center ul,
ul.list-check-center {
  padding-left: 0;
  text-align: center;
  list-style: none;

  li {

    &::before {
      @include icon-fas($fa-var-check);

      width: 1.5em;
      color: $primary;
    }
  }
}


// Tabellen
// --------------------------------------------------

.table {
  --bs-table-bg: unset;
}

// Hyperlinks und Buttons
// --------------------------------------------------

a:not(.btn),
button:not(.btn) {
  transition: color .3s ease-in-out, background-color .3s ease-in-out, border-color .3s ease-in-out;
}

a {}

.ce_hyperlink {
  margin: 0 0 ($paragraph-margin-bottom);
}

.btn {
}

.btn-primary {
  color: $white;
}


// Hyperlinks mit Icon
// --------------------------------------------------

// Icons rechts mit variabler Breite

.link-icon-right {
  text-indent: 0 !important;

  &::after {
    @include icon-fas();
  }

  &.icon-angle-double-up {

    &::after {
      content: my-icon2($icon-empty-space, $fa-var-angle-double-up);
    }
  }

  &.icon-angle-double-right {

    &::after {
      content: my-icon2($icon-empty-space, $fa-var-angle-double-right);
    }
  }

  &.icon-angle-double-down {

    &::after {
      content: my-icon2($icon-empty-space, $fa-var-angle-double-down);
    }
  }

  &.icon-angle-double-left {

    &::after {
      content: my-icon2($icon-empty-space, $fa-var-angle-double-left);
    }
  }

  &.icon-external-link-alt {

    &::after {
      content: my-icon2($icon-empty-space, $fa-var-external-link-alt);
    }
  }
}


// Icons links mit variabler Breite

.link-icon-left {

  &::before {
    @include icon-fas();
  }

  &.icon-angle-double-up {

    &::before {
      content: my-icon2($fa-var-angle-double-up, $icon-empty-space);
    }
  }

  &.icon-angle-double-right {

    &::before {
      content: my-icon2($fa-var-angle-double-right, $icon-empty-space);
    }
  }

  &.icon-angle-double-down {

    &::before {
      content: my-icon2($fa-var-angle-double-down, $icon-empty-space);
    }
  }

  &.icon-angle-double-left {

    &::before {
      content: my-icon2($fa-var-angle-double-left, $icon-empty-space);
    }
  }

  &.icon-external-link-alt {

    &::before {
      content: my-icon2($fa-var-external-link-alt, $icon-empty-space);
    }
  }
}


// Icons links mit fester Breite

.link-icon-left-fw {
  position: relative;
  display: inline-block;
  padding-left: 1.2em;

  &::before {
    @include icon-fas();

    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 1em;
    height: 100%;
    text-align: center;
  }

  &.icon-angle-double-up {

    &::before {
      content: my-icon1($fa-var-angle-double-up);
    }
  }

  &.icon-angle-double-right {

    &::before {
      content: my-icon1($fa-var-angle-double-right);
    }
  }

  &.icon-angle-double-down {

    &::before {
      content: my-icon1($fa-var-angle-double-down);
    }
  }

  &.icon-angle-double-left {

    &::before {
      content: my-icon1($fa-var-angle-double-left);
    }
  }

  &.icon-external-link-alt {

    &::before {
      content: my-icon1($fa-var-external-link-alt);
    }
  }
}


// Styles für .ce_text
// --------------------------------------------------

.ce_text {

  // Abstände bei Bildern in Abhängigkeit der Ausrichtung innerhalb von .ce_text

  .image_container {

    &.float_right {
      float: right;
      margin-left: ($grid-gutter-width);
    }

    &.float_left {
      float: left;
      margin-right: ($grid-gutter-width);
    }

    &.float_above,
    &.float_right,
    &.float_left {

      // Abstand von Überschriften nach einem Bild

      + h1,
      + .h1,
      + h2,
      + .h2,
      + h3,
      + .h3,
      + h4,
      + .h4,
      + h5,
      + .h5,
      + h6,
      + .h6 {
        margin-top: 0;
      }
    }

    &.float_right,
    &.float_left {

      @include media-breakpoint-down(md) {
        float: none;
        margin-right: 0;
        margin-left: 0;
      }
    }
  }

  &.img-float_left,
  &.img-float_right {
    overflow: hidden;
  }

  &.img-float_left {

    > ol,
    > ul {
      overflow: hidden;
    }
  }

  &.img-float_below {

    p:nth-last-child(2) {
      margin-bottom: 0;
    }
  }
}


// Bilder
// --------------------------------------------------

img {
  width: auto;
}

.ce_image {
  margin-bottom: $paragraph-margin-bottom;

  > figure {
    margin-bottom: 0;
  }
}

.image_container {

  &.img-caption {
    // display: table;

    img {
      // border-bottom-right-radius: 0 !important;
      // border-bottom-left-radius: 0 !important;
    }

    figcaption {
      // display: table-caption;
      // padding: map-get($spacers, 050);
      // font-size: $font-size-xs;
      // line-height: $line-height-sm;
      // caption-side: bottom;
      // background-color: $light;
    }
  }
}


// Galerie responsiv
// --------------------------------------------------

.ce_gallery {

  figure {
    margin-bottom: 0;
  }

  ul {
    @include make-row();

    justify-content: center;
    margin-top: ($paragraph-margin-bottom * .5);
    padding: 0;
    list-style: none;

    li {
      @include make-col-ready();

      padding-bottom: ($grid-gutter-width);
    }

    &.cols_2 {

      li {
        @include media-breakpoint-up(xs) {
          @include make-col(6);
        }
      }
    }

    &.cols_3 {

      li {
        @include media-breakpoint-up(xs) {
          @include make-col(6);
        }

        @include media-breakpoint-up(md) {
          @include make-col(4);
        }
      }
    }

    &.cols_4 {

      li {
        @include media-breakpoint-up(xs) {
          @include make-col(6);
        }

        @include media-breakpoint-up(md) {
          @include make-col(3);
        }
      }
    }

    &.cols_5 {

      li {
        @include media-breakpoint-up(xs) {
          @include make-col(6);
        }

        @include media-breakpoint-up(md) {
          @include make-col(1, 4);
        }
      }
    }

    &.cols_6 {

      li {
        @include media-breakpoint-up(xs) {
          @include make-col(6);
        }

        @include media-breakpoint-up(md) {
          @include make-col(2);
        }
      }
    }

    &.cols_7 {

      li {
        @include media-breakpoint-up(xs) {
          @include make-col(6);
        }

        @include media-breakpoint-up(md) {
          @include make-col(1, 7);
        }
      }
    }

    &.cols_8 {

      li {
        @include media-breakpoint-up(xs) {
          @include make-col(6);
        }

        @include media-breakpoint-up(md) {
          @include make-col(1, 8);
        }
      }
    }

    &.cols_9 {

      li {
        @include media-breakpoint-up(xs) {
          @include make-col(6);
        }

        @include media-breakpoint-up(md) {
          @include make-col(1, 9);
        }
      }
    }

    &.cols_10 {

      li {
        @include media-breakpoint-up(xs) {
          @include make-col(6);
        }

        @include media-breakpoint-up(md) {
          @include make-col(1, 10);
        }
      }
    }

    &.cols_11 {

      li {
        @include media-breakpoint-up(xs) {
          @include make-col(6);
        }

        @include media-breakpoint-up(md) {
          @include make-col(1, 11);
        }
      }
    }

    &.cols_12 {

      li {
        @include media-breakpoint-up(xs) {
          @include make-col(6);
        }

        @include media-breakpoint-up(md) {
          @include make-col(1);
        }
      }
    }
  }
}
