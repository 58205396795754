//
// xxx
// Stand: xxx
// © hofff.com | Nicky Hoff
// nicht-semantische Hilfsklassen, sowie
// benutzerspezifische Styles zur Benutzung durch den Backenduser
// --------------------------------------------------

// Hilfsklassen
// --------------------------------------------------

a.invisible,
a span.invisible,
label span.invisible,
legend span.invisible,
table span.invisible {
  @include visually-hidden();
}


// Border
// -------------------------

.ce_hofff_horizontalrule {
  position: relative;
  margin-top: map-get($spacers, 250);
  margin-bottom: map-get($spacers, 250);

  .widget-hr & {
    margin-top: map-get($spacers, 100);
  }

  &[class*="col-"] {
    // padding: 0;
    // border: none;

    &::before {
      // content: '';
      // display: block;
      // height: 1px;
      // margin-right: ($grid-gutter-width * .5);
      // margin-left: ($grid-gutter-width * .5);
      // background-color: $hr-border;
    }
  }

  hr {
    margin-top: 0;
    margin-bottom: 0;
  }

  a {
    position: absolute;
    top: -.7em;
    left: calc(50% - 4em);
    display: block;
    width: 8em;
    font-size: $font-size-md;
    text-align: center;
    text-decoration: none;
    background-color: $white;

    &::before {
      @include icon-fas();

      content: my-icon2($fa-var-angle-double-up, $icon-empty-space);
    }
  }

  &.w-100 {
    position: static;
    margin-top: 0;
    margin-bottom: 0;

    hr {
      height: 0;
      color: transparent;
    }
  }
}


// Colorbox
// --------------------------------------------------

// stylelint-disable selector-id-pattern

#colorbox,
#cboxOverlay,
#cboxWrapper {
  overflow: visible !important;
}

div#cboxTitle {
  top: 100%;
  margin-right: 5px;
  margin-left: 5px;
}

// stylelint-enable selector-id-pattern


// Alerts & Cards
// --------------------------------------------------

.alert,
.card-body {

  > :first-child {
    margin-top: 0;
    padding-top: 0;
  }

  > p:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.card {

  .image_container {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}


// Grid
// --------------------------------------------------

.col-ready {
  @include make-col-ready();

  // width: 100%;
}


// Responsive videos
// --------------------------------------------------

.ce_player,
.ce_youtube,
.ce_vimeo {
  margin-bottom: ($paragraph-margin-bottom * 2);
}

.video_container {
  margin: 0;

  video,
  audio {
    width: 100%;
    height: auto;
  }

  .responsive {
    @extend .ratio;

    &.ratio-169 {
      @extend .ratio-16x9;
    }

    &.ratio-1610 {
      @extend .ratio-16x10;
    }

    &.ratio-219 {
      @extend .ratio-21x9;
    }

    &.ratio-43 {
      @extend .ratio-4x3;
    }

    &.ratio-32 {
      @extend .ratio-3x2;
    }
  }
}
