/*! xxx, Stand: xxx, © hofff.com | Nicky Hoff
based on: Bootstrap v4.4.1 (https://getbootstrap.com/), Copyright 2011-2019 The Bootstrap Authors, Copyright 2011-2019 Twitter, Inc., Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
*/

@import "~bootstrap/scss/mixins/banner";
@include bsBanner("");


// SCSS RFS mixin
// Configuration

// Base value
$rfs-base-value: 1rem;
// Reduzierung, wenn Schriftgröße größer als:
// .75rem = 12px
// .875rem = 14px
// 1rem = 16px
// 1.125rem = 18px
// 1.25rem = 20px
// 1.375rem = 22px
$rfs-unit: rem;

// Breakpoint at where values start decreasing if screen width is smaller
$rfs-breakpoint: 1200px;
$rfs-breakpoint-unit: px;

// Resize values based on screen height and width
$rfs-two-dimensional: false;

// Factor of decrease
$rfs-factor: 30;
// 20 -> initiale Schriftgröße
// Reduzierung bei 414px Screenbreite
// 10 -> 16.914px
// 20 -> 16.853666px
// 30 -> 16.833555px
// 40 -> 16.8235px
// 50 -> 16.817467px

// Mode. Possibilities: "min-media-query", "max-media-query"
$rfs-mode: min-media-query;

// Generate enable or disable classes. Possibilities: false, "enable" or "disable"
$rfs-class: false;

// 1 rem = $rfs-rem-value px
$rfs-rem-value: 16;

// Safari iframe resize bug: https://github.com/twbs/rfs/issues/14
$rfs-safari-iframe-resize-bug-fix: false;


// scss-docs-start import-stack
// Configuration

@import "~bootstrap/scss/functions";

@import "fonts";

@import "variables";

@import "mixins/default";

// @import "icon-font";

@import "~@fortawesome/fontawesome-free/scss/fontawesome";

@import "~@fortawesome/fontawesome-free/scss/brands";

@import "~@fortawesome/fontawesome-free/scss/regular";

@import "~@fortawesome/fontawesome-free/scss/solid";

@import "~bootstrap/scss/variables";

// @import "~bootstrap/scss/variables-dark";

@import "~bootstrap/scss/maps";

@import "~bootstrap/scss/mixins";

@import "~bootstrap/scss/utilities";


// Layout & components

@import "~bootstrap/scss/root";

@import "~bootstrap/scss/reboot";

@import "~bootstrap/scss/type";

@import "~bootstrap/scss/images";

@import "~bootstrap/scss/containers";

@import "~bootstrap/scss/grid";

@import "~bootstrap/scss/tables";

@import "~bootstrap/scss/forms";

@import "~bootstrap/scss/buttons";

// @import "~bootstrap/scss/transitions";

// @import "~bootstrap/scss/dropdown";

@import "~bootstrap/scss/button-group";

// @import "~bootstrap/scss/nav";

// @import "~bootstrap/scss/navbar";

@import "~bootstrap/scss/card";

@import "~bootstrap/scss/accordion";

// @import "~bootstrap/scss/breadcrumb";

// @import "~bootstrap/scss/pagination";

// @import "~bootstrap/scss/badge";

@import "~bootstrap/scss/alert";

// @import "~bootstrap/scss/progress";

@import "~bootstrap/scss/list-group";

// @import "~bootstrap/scss/close";

// @import "~bootstrap/scss/toasts";

// @import "~bootstrap/scss/modal";

// @import "~bootstrap/scss/tooltip";

// @import "~bootstrap/scss/popover";

// @import "~bootstrap/scss/carousel";

// @import "~bootstrap/scss/spinners";

// @import "~bootstrap/scss/offcanvas";

// @import "~bootstrap/scss/placeholders";


// Helpers

@import "~bootstrap/scss/helpers";


// Utilities

@import "~bootstrap/scss/utilities/api";


// my individual files

@import "common";

@import "layout";

@import "nav";

@import "custom";

@import "individual";

@import "slider";

@import "form";

@import "helper";

// @import "extend-variables";
// scss-docs-end import-stack
