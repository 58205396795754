//
// XXX
// Stand: XXX
// © hofff.com | Nicky Hoff
// Slider Styles
// --------------------------------------------------


// Slider
// --------------------------------------------------

.pw-mainteaser-default {

  > .pw-mainteaser-default-element {
    position: relative;
    background-color: $gray-200;
    border-bottom: solid $primary 2px;
    border-bottom-right-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }

  .ce_image,
  .image_container {
    margin-bottom: 0;
  }

  .pw-slider-inner {

    @include media-breakpoint-up(md) {
      position: absolute;
      bottom: ($grid-gutter-width);
      left: 0;
      width: 100%;
      padding: 0;
    }

    .pw-slider-elements {
      @include make-col-ready();
      @include make-col(12);

      padding-bottom: ($grid-gutter-width * .5);

      @include media-breakpoint-up(md) {
        @include make-col(6);

        .ce_headline,
        .ce_headline_virtual,
        p {
          padding-right: ($grid-gutter-width * .25);
          padding-left: ($grid-gutter-width * .25);
          color: $white;
          line-height: 1.4;

          > span {
            background-color: rgba($my-color-r, $my-color-g, $my-color-b, .5);
            box-shadow: ($grid-gutter-width * .25) 0 0 0 rgba($my-color-r, $my-color-g, $my-color-b, .5), -($grid-gutter-width * .25) 0 0 0 rgba($my-color-r, $my-color-g, $my-color-b, .5);
            box-decoration-break: clone;
          }
        }
      }

      .ce_hyperlink {

        @include media-breakpoint-up(md) {
          margin-bottom: 0;
        }
      }
    }
  }
}

.mod_rocksolid_slider {

  .rsts-view {
    overflow: hidden;
    border-radius: $border-radius;
  }

  &.rsts-skin-default {

    .rsts-nav {
      top: ($grid-gutter-width * .5);
      left: auto;

      @include media-breakpoint-up(md) {
        top: auto;
        bottom: ($grid-gutter-width * 1.5);
      }
    }

    .rsts-prev,
    .rsts-next {
      top: ($grid-gutter-width * .5);
      bottom: auto;

      @include media-breakpoint-up(md) {
        top: auto;
        bottom: ($grid-gutter-width * 1.5);
      }
    }
  }
}
