//
// xxx
// Stand: xxx
// © hofff.com | Nicky Hoff
// Sonstige benutzerdefinierte Styles
// --------------------------------------------------


// Logo
// --------------------------------------------------

.pw-logo {
  padding-top: map-get($spacers, 100);
  padding-bottom: map-get($spacers, 100);

  @include media-breakpoint-up(lg) {
    @include make-col-auto();
  }

  p {
    margin: 0;
    padding: 0;
    font-size: $h2-font-size;
    line-height: 1;

    strong {
      font-size: $h1-font-size;
    }
  }

  a {
    color: $primary;

    strong {
      color: $gray-700;
    }
  }

  .ce_image {
    margin-bottom: 0;
  }
}


// Spezialteaser
// --------------------------------------------------

.pw-teaser-special {
  position: relative;
  margin-top: 40px;

  &::before {
    position: absolute;
    top: 0;
    left: ($grid-gutter-width * .5);
    z-index: 99;
    width: calc(100% - #{$grid-gutter-width});
    height: 80%;
    background-color: $white;
    box-shadow: 0 0 15px $gray-700;
    content: "";
  }

  @include media-breakpoint-up(md) {
    left: -30px;
    margin-top: 0;
    margin-left: auto;

    &::before {
      top: -$grid-gutter-width;
      left: 0;
      width: 100%;
      height: 40px;
    }
  }

  > .row {
    @extend .justify-content-center;

    > div {
      z-index: 100;
      padding-right: ($grid-gutter-width * .5);
      padding-left: ($grid-gutter-width * .5);
      background-color: $white;

      @include media-breakpoint-down(md) {
        flex: 0 0 calc(100% - #{$grid-gutter-width});
        max-width: calc(100% - #{$grid-gutter-width});
      }

      @include media-breakpoint-up(md) {
        padding-right: $grid-gutter-width;
        padding-left: $grid-gutter-width;

        :first-child {
          margin-top: 0;
          padding-top: 0;
        }
      }
    }
  }
}


// Cards
// --------------------------------------------------

.card {
  margin-top: map-get($spacers, 200);
  margin-bottom: map-get($spacers, 200);

  .ce_image {
    margin-bottom: 0;

    &.card-top {

      img {
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
      }
    }

    &:not(.card-top) {

      img {
        border-radius: 0 !important;
      }
    }
  }

  .card-header,
  .card-footer {

    :first-child {
      margin-top: 0;
      padding-top: 0;
    }

    :last-child {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  &.bg-light {
    border: none;

    .card-header,
    .card-footer {
      background-color: $gray-600;

      * {
        color: $white;
      }
    }
  }

  &.border-primary {

    .card-header {
      background-color: $primary;

      * {
        color: $white;
      }
    }
  }
}


// Akkordeon
// --------------------------------------------------

.ce_accordion {
  border-bottom: 1px solid $primary;

  .toggler {
    position: relative;
    padding: map-get($spacers, 100) 0 map-get($spacers, 100) map-get($spacers, 150);
    overflow: hidden;
    color: $primary;
    font-weight: $font-weight-bold;
    cursor: pointer;
    transition: color .3s ease-out;

    &::before,
    &::after {
      @include icon-fas();

      position: absolute;
      left: 2px;
      transition: top .3s ease-out;
    }

    &::before {
      top: 28%;
      content: my-icon1($fa-var-angle-double-right);
    }

    &::after {
      top: 112%;
      content: my-icon1($fa-var-angle-double-down);
    }

    &:hover {
      color: $gray-900;

      &::before {
        top: -52%;
        color: $primary;
      }

      &::after {
        top: 28%;
        color: $gray-900;
      }
    }

    &.active {
      color: $body-color;

      &::before {
        top: -52%;
        color: $body-color;
        content: my-icon1($fa-var-angle-double-up);
      }

      &::after {
        top: 28%;
        color: $body-color;
        content: my-icon1($fa-var-angle-double-down);
      }

      &:hover {
        color: $primary;

        &::before {
          top: 28%;
          color: $primary;
        }

        &::after {
          top: 112%;
          color: $body-color;
        }
      }
    }
  }

  .accordion {

    > div {
      padding-bottom: map-get($spacers, 100);
      padding-left: map-get($spacers, 150);

      :last-child {
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
}


// Tabellen
// --------------------------------------------------

.table-sm {

  th,
  td {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
  }
}


// Meta-Text Subfooter
// --------------------------------------------------

.pw-meta-text {
  font-size: $font-size-sm;
  text-align: center;

  @include media-breakpoint-up(lg) {
    text-align: right;
  }

  > div {
    display: inline-block;
  }

  p {
    margin-bottom: 0;
  }
}


// Google Maps
// --------------------------------------------------

.googlemap {
  width: 100%;
  height: 500px;
  margin-top: $grid-gutter-width;
  border-radius: $border-radius;

  @include media-breakpoint-up(md) {
    height: 500px;
  }

  @include media-breakpoint-up(lg) {
    height: 400px;
  }

  @include media-breakpoint-up(xlg) {
    height: 350px;
  }
}


// Consent Banner
// --------------------------------------------------

.hofff-consent-privacy-settings-button {

  button {
    @include font-size($font-size-md);
  }
}
