//
// xxx
// Stand: xxx
// © hofff.com | Nicky Hoff
// Styles für die Layoutbereiche
// --------------------------------------------------


html {
  scroll-behavior: smooth;
}

body {

  &.debug {

    &::before {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1000000;
      display: block;
      content: "XXS";

      @include media-breakpoint-up(xs) {
        content: "XS";
      }

      @include media-breakpoint-up(sm) {
        content: "SM";
      }

      @include media-breakpoint-up(md) {
        content: "MD";
      }

      @include media-breakpoint-up(lg) {
        content: "LG";
      }

      @include media-breakpoint-up(xl) {
        content: "XL";
      }

      @include media-breakpoint-up(xxl) {
        content: "XXL";
      }

      @include media-breakpoint-up(xxxl) {
        content: "3XL";
      }
    }
  }
}


// ALLGEMEIN
// --------------------------------------------------

#header,
#footer,
#container,
#mainteaser,
#subfooter,
#hofff-consent-banner {

  > .inside {
    @include make-container();

    @include media-breakpoint-up(xl) {
      width: map-get($container-max-widths, xl);
    }

    @include media-breakpoint-up(xxl) {
      width: map-get($container-max-widths, xxl);
    }
  }
}

#header {

  > .inside {

    > .row {

      @include media-breakpoint-up(md) {
        justify-content: center;
      }

      @include media-breakpoint-up(lg) {
        justify-content: space-between;
      }
    }
  }
}

#left {

  @include media-breakpoint-up(sm) {
    @include make-col(3);
  }
}

#main {
  padding-bottom: map-get($spacers, 200);

  .layout-col-left & {

    @include media-breakpoint-up(sm) {
      @include make-col(9);
    }
  }
}

#right {

  @include media-breakpoint-up(sm) {
    @include make-col(3);
  }
}

#subfooter {

  > .inside {

    > .row {
      margin: 0 0 ($grid-gutter-width * .5);
      padding: map-get($spacers, 100) 0;
      background-color: $light;
      border-bottom: solid $primary 2px;
      border-radius: $card-border-radius;
    }
  }
}

#footer {
  padding-bottom: map-get($spacers, 050);

  > .inside {

    > .row {
      align-items: center;
      justify-content: center;

      @include media-breakpoint-up(lg) {
        justify-content: space-between;
      }
    }
  }

  .pw-footer-nav {
  }

  .pw-footer-hl {
    padding-bottom: map-get($spacers, 050);
    text-align: center;
  }

  .pw-footer-nav,
  .pw-footer-hl {
    @include make-col-auto();


    @include media-breakpoint-up(lg) {
    }
  }
}
