//
// xxx
// Stand: xxx
// © hofff.com | Nicky Hoff
// Styles für Navigationen
// --------------------------------------------------


// mobile Navi
// --------------------------------------------------

.navi-main-toggler-open,
.navi-main-toggler-close {
  margin-bottom: 0;

  button {
    @extend .btn;
  }
}

.navi-main-toggler-open {
  position: absolute;
  top: ($grid-gutter-width * .5);
  right: ($grid-gutter-width * .5);

  button {
    @extend .btn-primary;
  }
}

.navi-main-toggler-close {
  padding: ($grid-gutter-width * .5) ($grid-gutter-width * .5) (1.65rem + ($grid-gutter-width * .5));
  text-align: right;
  border-bottom: solid $white 3px;

  button {
    @extend .btn-secondary;
  }
}

.navi-main-container {

  @include media-breakpoint-up(lg) {
    @include make-col-auto();
  }

  @include media-breakpoint-down(md) {
    margin: 0;
    padding: 0;
    background: $primary;

    &.cbp-spmenu {
      position: fixed;
      transition: right .3s ease;
    }

    &.cbp-spmenu-vertical {
      top: 0;
      z-index: 101;
      width: 300px;
      height: 100%;
      overflow-y: scroll;
    }

    &.cbp-spmenu-right {
      right: -100%;
      //right: 0;

      &.menu-open {
        right: 0;
      }
    }
  }

  @include media-breakpoint-down(xs) {

    &.cbp-spmenu-vertical {
      width: 100%;
    }
  }
}


// Hauptnavigation
// --------------------------------------------------

.nav-main {
  height: 100%;
  text-align: center;

  @include media-breakpoint-up(md) {
    position: relative;
    z-index: 100;
    border-top: solid $hr-border-width $hr-border-color;
  }

  @include media-breakpoint-up(lg) {
    border-top: none;
  }

  .level_1 {
    @include nav-default();

    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: center;
      height: 100%;
    }

    @include media-breakpoint-up(lg) {
      justify-content: flex-end;
    }

    > li {
      position: relative;
      margin: 0;
      padding: 0;
      border-bottom: solid $white 1px;

      &:first-child {
      }

      &:last-child {

        > a,
        > strong {

          @include media-breakpoint-up(lg) {
            padding-right: 0;
          }
        }
      }

      @include media-breakpoint-up(md) {
        display: flex;
        align-items: center;
        height: 100%;
        border-bottom: none;
      }

      > a,
      > strong {
        position: relative;
        display: block;
        padding: map-get($spacers, 050);
        color: $white;
        font-weight: $nav-main-font-weight;
        font-size: $nav-main-font-size;
        text-transform: uppercase;

        @include media-breakpoint-up(md) {
          padding: map-get($spacers, 050) map-get($spacers, 150);
          color: $nav-main-color;

          &::after {
            position: absolute;
            bottom: 0;
            left: map-get($spacers, 150);
            width: 50px;
            height: 2px;
            background-color: $primary;
            opacity: 0;
            transition: opacity .3s ease-in;
            content: "";
          }
        }

        @include media-breakpoint-up(lg) {
          padding: map-get($spacers, 050) map-get($spacers, 100);

          &::after {
            left: map-get($spacers, 100);
          }
        }

        @include media-breakpoint-up(xl) {
          padding: map-get($spacers, 050) map-get($spacers, 150);

          &::after {
            left: map-get($spacers, 150);
          }
        }
      }

      a:hover,
      a.trail,
      strong {
        color: $black;

        @include media-breakpoint-up(md) {
          color: $primary;
        }
      }

      > strong,
      > a.active {
        font-weight: $font-weight-normal;
      }

      &:hover,
      &:focus {

        > a,
        strong {
          color: $black;

          @include media-breakpoint-up(md) {
            color: $primary;

            &::after {
              opacity: 1;
            }
          }
        }

        .level_2 {

          @include media-breakpoint-up(md) {
            top: 100%;
            left: 0;
            opacity: 1;
            transition: left 1ms linear, top 1ms linear, opacity .3s ease-in;

            .touch-device & {
              display: block;
              transition: none;
            }
          }
        }
      }
    }
  }

  .level_2 {
    @include nav-default(0, 0 0 map-get($spacers, 100), $line-height-base);

    @include media-breakpoint-up(md) {
      position: absolute;
      top: -10000px;
      //top: 0;
      left: -10000px;
      z-index: 1;
      //left: 0;
      min-width: 100%;
      padding: 0;
      overflow: hidden;
      font-size: $font-size-base;
      line-height: 1;
      white-space: nowrap;
      text-align: left;
      //opacity: 1;
      background-color: $white;
      opacity: 0;
      transition: left 1ms linear .3s, top 1ms linear .3s, opacity .3s ease-out;

      .touch-device & {
        display: none;
        transition: none;
      }
    }

    > li {
      padding: 0;

      @include media-breakpoint-up(md) {
        border-bottom: solid 1px $gray-300;
      }

      &.first {
        //background-color: #0f6674;
      }

      &.last {
        border-bottom: none;
      }

      > a,
      > strong {
        display: block;
        padding: 0 ($grid-gutter-width * .5);
        color: $white;

        @include media-breakpoint-up(md) {
          padding: map-get($spacers, 100) map-get($spacers, 150);
          color: $gray-700;
        }

        @include media-breakpoint-up(lg) {
          padding: map-get($spacers, 100);
        }

        @include media-breakpoint-up(xl) {
          padding: map-get($spacers, 100) map-get($spacers, 150);
        }
      }

      a:hover,
      a.trail,
      strong {
        color: $black;

        @include media-breakpoint-up(md) {
          color: $primary;
        }
      }

      strong {
        font-weight: $font-weight-base;
      }
    }
  }
}


// Meta
// --------------------------------------------------

.nav-meta {
  display: inline-block;
  padding-left: map-get($spacers, 025);
  font-size: $font-size-sm;

  .level_1 {
    @include nav-default();

    > li {
      position: relative;
      display: inline-block;
      margin: 0;
      padding: 0 map-get($spacers, 050);

      &::before {
        position: absolute;
        top: 20%;
        left: 0;
        display: block;
        width: 1px;
        height: 60%;
        background-color: $body-color;
        content: "";
      }

      &:last-child {
        padding-right: 0;
      }

      > strong,
      > a.active {
        font-weight: $font-weight-bold;
      }
    }
  }
}


// Consent
// --------------------------------------------------

.nav-consent {
  @include font-size($font-size-sm);

  .level_1 {
    @include nav-default();

    display: flex;

    > li {
      position: relative;
      margin: 0;
      padding: 0 map-get($spacers, 050);

      &::before {
        position: absolute;
        top: 20%;
        left: 0;
        display: block;
        width: 1px;
        height: 60%;
        background-color: $body-color;
        content: "";
      }

      &:first-child {
        padding-left: 0;

        &::before {
          content: none;
        }
      }

      &:last-child {
        padding-right: 0;
      }

      > a,
      > strong {

        &:hover,
        &.trail,
        &.active {
        }
      }

      > a:hover {
      }

      > a.trail {
      }

      > strong,
      > a.active {
        font-weight: $font-weight-bold;
      }
    }
  }
}


// ICONS
// --------------------------------------------------

.nav-social-icons {
  padding-bottom: map-get($spacers, 050);
  font-size: .5rem;

  ul {
    @include nav-default(0, 0, 1);

    display: flex;
    justify-content: center;

    li {
      margin: 0;
      padding: 0;

      &.first {
        padding-left: 0;
      }

      &.last {
        padding-right: 0;
      }

      a {
        display: block;
      }

      &.-ctx-fab-instagram a {
        color: #3f729b;
      }

      &.-ctx-fab-facebook a {
        color: #3b5998;
      }

      &.-ctx-fas-envelope-open-text a {
        color: #2d894b;
      }
    }
  }
}
